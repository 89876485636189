var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex mt-n8 mb-3"},[_c('v-spacer'),_c('v-speed-dial',{ref:"dial",staticClass:"account-dial",attrs:{"top":"","right":"","direction":"left","transition":"scale-transition"},on:{"input":function (){}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","fab":""},model:{value:(_vm.showDial),callback:function ($$v) {_vm.showDial=$$v},expression:"showDial"}},[(_vm.showDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-horizontal ")])],1)]},proxy:true}]),model:{value:(_vm.showDial),callback:function ($$v) {_vm.showDial=$$v},expression:"showDial"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"pink darken-1"},on:{"click":_vm.toggleLayout}},on),[_c('v-icon',[_vm._v("mdi-shape")])],1)]}}])},[_c('span',[_vm._v("Cambia layout")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":_vm.toggleGroupApps}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal-bold")])],1)]}}])},[_c('span',[_vm._v("Cambia visualizzazione account/applicazione")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();_vm.showAddAccount = true}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi un account tramite credenziali")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();_vm.showAddValidation = true}}},on),[_c('v-icon',[_vm._v("mdi-file-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi un account tramite codice di validazione")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#ff5722"},on:{"click":function($event){$event.stopPropagation();return _vm.startDelete.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("mdi-account-minus")])],1)]}}])},[_c('span',[_vm._v("Rimuovi account agganciati")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.startDefault.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Imposta account di default")])]),(_vm.user.can_map)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"yellow darken-3"},on:{"click":function($event){$event.stopPropagation();return _vm.startLinking.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,false,4045261312)},[_c('span',[_vm._v("Collega gli utenti tra applicazioni")])]):_vm._e()],1)],1),_c(_vm.listComponent,{tag:"component",attrs:{"items":_vm.items,"is-deleting":_vm.isDeleting,"is-defaulting":_vm.isDefaulting,"is-linking":_vm.isLinking},on:{"deleteAccount":_vm.deleteAccount,"doAppLogin":_vm.doAppLogin,"setDefault":_vm.setDefault,"linkAccounts":_vm.linkAccounts,"linkAccountsOver":_vm.linkAccountsOver,"linkAccountsLeave":_vm.linkAccountsLeave}}),_c('AddValidationCode',{model:{value:(_vm.showAddValidation),callback:function ($$v) {_vm.showAddValidation=$$v},expression:"showAddValidation"}}),_c('AddAccount',{model:{value:(_vm.showAddAccount),callback:function ($$v) {_vm.showAddAccount=$$v},expression:"showAddAccount"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }