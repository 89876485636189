<template>
  <v-dialog
    v-model="showMe"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title
        class="text-h5 secondary white--text"
      >
        <v-icon
          class="white--text mr-2"
          large
        >
          mdi-file-plus
        </v-icon>
        Aggiungi account
      </v-card-title>
      <v-card-text>
        <p class="subtitle1 mt-2">
          Aggiungi o aggiorna un nuovo account tramite il codice di validazione.
        </p>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="vcode"
                  :rules="rules"
                  :error="error"
                  :error-message="errorMessage"
                  label="Codice di validazione"
                  clearable
                  counter
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          outlined
          @click="showMe = false"
        >
          Annulla
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          @click="sendCode"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      error: null,
      errorMessage: null,
      vcode: '',
      rules: [
        v => !!v || 'Codice obbligatorio',
        v => (v && v.length > 10) || 'Codice non valido',
        v => (v && /^\w-/.test(v)) || 'Codice non valido 2'
      ]
    }
  },
  computed: {
    showMe: {
      get () { return this.value || false },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    ...mapMutations('account', ['setAccounts']),
    sendCode () {
      if (this.$refs.form.validate()) {
        this.error = null
        this.errorMessage = null
        this.resetValidation()
        this.$http.post('/accounts/apps/account/add_app_account/', { vcode: this.vcode })
          .then(r => {
            this.setAccounts(r.data)
            this.showMe = false
          })
          .catch(error => {
            this.error = true
            this.errorMessage = error.response.data
          })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
