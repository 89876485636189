<template>
  <div style="width: 100%;">
    <div class="d-flex mt-n8 mb-3">
      <v-spacer />
      <v-speed-dial
        v-model="showDial"
        top
        right
        direction="left"
        transition="scale-transition"
        class="account-dial"
        @input="()=>{}"
        ref="dial"
      >
        <template #activator>
          <v-btn
            v-model="showDial"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="showDial">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="pink darken-1"
              @click="toggleLayout"
              v-on="on"
            >
              <v-icon>mdi-shape</v-icon>
            </v-btn>
          </template>
          <span>Cambia layout</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              @click="toggleGroupApps"
              v-on="on"
            >
              <v-icon>mdi-swap-horizontal-bold</v-icon>
            </v-btn>
          </template>
          <span>Cambia visualizzazione account/applicazione</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-on="on"
              @click.stop="showAddAccount = true"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi un account tramite credenziali</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-on="on"
              @click.stop="showAddValidation = true"
            >
              <v-icon>mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi un account tramite codice di validazione</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="#ff5722"
              v-on="on"
              @click.stop="startDelete"
            >
              <v-icon>mdi-account-minus</v-icon>
            </v-btn>
          </template>
          <span>Rimuovi account agganciati</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="blue darken-2"
              v-on="on"
              @click.stop="startDefault"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Imposta account di default</span>
        </v-tooltip>
        <v-tooltip
          v-if="user.can_map"
          left
        >
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="yellow darken-3"
              v-on="on"
              @click.stop="startLinking"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span>Collega gli utenti tra applicazioni</span>
        </v-tooltip>
      </v-speed-dial>
    </div>
    <component
      :is="listComponent"
      :items="items"
      :is-deleting="isDeleting"
      :is-defaulting="isDefaulting"
      :is-linking="isLinking"
      @deleteAccount="deleteAccount"
      @doAppLogin="doAppLogin"
      @setDefault="setDefault"
      @linkAccounts="linkAccounts"
      @linkAccountsOver="linkAccountsOver"
      @linkAccountsLeave="linkAccountsLeave"
    />
    <AddValidationCode v-model="showAddValidation" />
    <AddAccount v-model="showAddAccount" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import AppList from './AppList.vue'
import AccountList from './AccountList.vue'
import AddValidationCode from './AddValidation.vue'
import AddAccount from './AddAccount.vue'
import { isMobile } from 'mobile-device-detect'
import MappedManager from './MappedManager.js'

export default {
  components: {
    AddValidationCode,
    AddAccount
  },
  data () {
    return {
      isLinking: false,
      isDeleting: false,
      isDefaulting: false,
      showAddValidation: false,
      showAddAccount: false,
      localShowDial: true,
      localLinked: [],
      currentLine: null,
      mapman: null,
      lines: {}
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('dashboard', ['groupApps']),
    ...mapGetters('account', ['linked']),
    accounts () {
      return this.$store.state.account.accounts
    },
    showDial: {
      get () {
        if (this.isEditing) return false
        return this.localShowDial
      },
      set (value) {
        if (!this.isEditing) this.localShowDial = value
      }
    },
    groupAppsView: {
      get () {
        return this.$store.state.dashboard.groupApps
      },
      set (val) {
        this.setGroupApps(val)
      }
    },
    listComponent () {
      if (this.groupApps) return AppList
      return AccountList
    },
    items () {
      if (this.groupApps) return this.mapAccounts()
      return this.accounts
    },
    isEditing () {
      return this.isLinking || this.isDeleting || this.isDefaulting || false
    }
  },
  mounted () {
    global.dashboard = this
    this.$http.get('/accounts/list_accounts/')
      .then(r => {
        this.setAccounts(r.data.map(o => ({ ...o, startLink: false, endLink: false })))
      })
      .catch(() => {})
    this.mapman = new MappedManager(this)
  },
  methods: {
    ...mapMutations('dashboard', ['setGroupApps', 'toggleGroupApps', 'toggleShowDial', 'toggleLayout']),
    ...mapMutations('account', ['setAccounts']),
    ...mapMutations('app', ['disableToolBar', 'enableToolBar']),
    async startLinking () {
      if (this.isEditing) return
      this.showDial = false
      this.isLinking = true
      this.disableToolBar()
      this.isLinking = await this.mapman.showDialog()
      this.enableToolBar()
    },
    linkAccounts (item, event) {
      this.mapman.select(item, event.shiftKey)
    },
    linkAccountsOver (item) {
      this.mapman.over(item)
    },
    linkAccountsLeave (item) {
      this.mapman.leave(item)
    },
    async startDefault () {
      if (this.isEditing) return
      this.showDial = false
      this.isDefaulting = true
      this.disableToolBar()
      this.isDefaulting = await this.$dialog.toast({
        text: 'Clicca sugli account da impostare come default',
        color: 'blue darken-2',
        position: 'top-center',
        dark: true,
        timeout: -1,
        actions: {
          false: {
            // flat: true,
            small: true,
            color: 'primary',
            text: 'Fatto'
          }
        }
      })
      this.enableToolBar()
    },
    async startDelete () {
      if (this.isEditing) return
      this.showDial = false
      this.isDeleting = true
      this.disableToolBar()
      this.isDeleting = await this.$dialog.message.warning('Clicca sugli account da eliminare', {
        position: 'top-center',
        dark: true,
        timeout: -1,
        actions: {
          false: {
            // flat: true,
            small: true,
            color: 'primary',
            text: 'Fatto'
          }
        }
      })
      this.enableToolBar()
    },
    async deleteAccount (item) {
      const res = await this.$dialog.prompt({
        title: `Eliminare l'account "${item.username}" per l'applicazione "${item.name}" ?`,
        text: 'Digita il nome dell\'account per confermare l\'eliminazione',
        type: 'warning',
        icon: 'mdi-alert',
        class: ['sso-dash-prompt-dialog'],
        actions: {
          false: {
            // flat: true,
            outlined: true,
            color: 'primary',
            text: 'Annulla'
          },
          true: {
            outlined: true,
            color: 'error',
            text: 'Elimina'
          }
        }
      })
      this.enableToolBar()
      if (res === item.username) {
        this.realDeleteAccount(item)
      }
    },
    realDeleteAccount (item) {
      this.$http.delete(`/accounts/list_accounts/${item.id}/`).then(r => { this.setAccounts(r.data) })
    },
    doAppLogin (item) {
      if (item.already_loggedin) {
        this.$http.get(item.already_loggedin).then(r => {
          if (r.data.loggedin) {
            this.$msgError(r.data.message)
          } else {
            window.open(item.link, isMobile ? '_self' : item.name)
          }
        })
      } else {
        window.open(item.link, isMobile ? '_self' : item.name)
      }
    },
    setDefault (item) {
      this.$http.patch(`/accounts/list_accounts/${item.id}/`).then(r => { this.setAccounts(r.data) })
    },
    mapAccounts () {
      const apps = {}
      this.accounts.forEach(account => {
        if (!(account.app_id in apps)) {
          apps[account.app_id] = {
            appId: account.app_id,
            name: account.name,
            description: account.description,
            logo: account.logo,
            accounts: []
          }
        }
        apps[account.app_id].accounts.push(account)
      })
      return Object.values(apps)
    }
    //
  }
}
</script>
<style lang="scss">
  .account-dial {
    // position: relative;
    // top: 20px;
    // float: right;
    .v-btn {
      padding: 0;
    }
  }
  .sso-dash-prompt-dialog {
    .v-card__title{
      word-break: break-word;
    }
  }
</style>
