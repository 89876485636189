/**
 * module LinkedItems.js
 */
import Vue from 'vue'
import LeaderLine from 'leader-line-new'
import convert from 'color-convert'
import axios from '@/plugins/axios.js'
import store from '@/store'
import MappedManagerDialog from './MappedManagerDialog.vue'

const lineOptions = {
  color: 'rgba(255, 124, 77, 0.7)',
  dropShadow: true,
  // startPlug: 'arrow2',
  startPlug: 'disc',
  endPlug: 'arrow2',
  hide: true,
  path: 'straight',
  size: 10
}
const user = store.getters['account/user']
const defaultOpcity = 0.3
const lastColorInc = 30
const lastColorBase = 15
let lastColor = lastColorBase

var getAvailColor = () => {
  if (lastColor < lastColorBase) lastColor = lastColorBase
  var color = convert.hsl.rgb(lastColor, 100, 65)
  lastColor += lastColorInc
  if (lastColor > 360) lastColor = lastColorBase
  return color
}

class Mapped {
  constructor (first, second, manager, type = null) {
    this.first = first
    this.second = second
    this.manager = manager
    this.type = type || ''
    this.line = null
    this.setProps()
    this._opacity = defaultOpcity
    this._color = getAvailColor()
    this._color.concat(this._opacity)
    this.line = new LeaderLine(this.start, this.end, {
      ...lineOptions,
      ...manager._lineProps,
      color: this.color,
      id: `${first.id}-${second.id}`
    })
    // this.element = document.body.querySelector(':scope>svg.leader-line:last-of-type')
    // this.element.classList.add('mapped-line')
    // this.element.classList.add(`mapped-id-${first.id}-${second.id}`)
    // this.element.addEventListener('click', function () { alert('Hello World!') })
  }

  get opacity () {
    return this._opacity
  }

  set opacity (value) {
    this._opacity = value
    this._color[3] = this._opacity
    var [r, g, b, a] = this._color
    this.line.color = `rgba(${r}, ${g}, ${b}, ${a})`
  }

  get color () {
    var [r, g, b, a] = this._color
    if (!a) a = defaultOpcity
    return `rgba(${r}, ${g}, ${b}, ${a})`
  }

  hasAccount (accountId) {
    if (accountId === this.first.id) return this.first
    if (accountId === this.second.id) return this.second
    return null
  }

  setProps () {
    // var start = document.getElementById(`${this.first.app_id}-${this.first.id}`)
    this.start = this.manager.getElement(this.first)
    this.start.isFirst = true
    this.start.isLast = false
    this.start.mapPosition = 'first'
    // var end = document.getElementById(`${this.second.app_id}-${this.second.id}`)
    this.end = this.manager.getElement(this.second)
    this.start.isFirst = false
    this.start.isLast = true
    this.start.mapPosition = 'last'
  }

  unsetProps () {
    try {
      delete this.line.start.isFirst
      delete this.line.start.isLast
      delete this.line.start.mapPosition
      delete this.line.end.isFirst
      delete this.line.end.isLast
      delete this.line.sen.mapPosition
    } catch (err) {
      // pass
    }
  }

  resetLine () {
    this.line.dash = false
  }

  removeLine () {
    try {
      this.unsetProps()
      this.line.remove()
      this.line = null
    } catch (err) {
      // pass
    }
  }

  cleanUp () {
    this.first.startLink = false
    this.second.endLink = false
    this.removeLine()
    lastColor -= lastColorInc
  }

  show (effect = 'draw') {
    if (this.line) this.line.show(effect)
  }

  hide (effect = 'draw') {
    if (this.line) this.line.hide(effect)
  }

  reveal () {
    this.line.dash = { animation: true }
    this.opacity = 1
    if (!this.manager.showLines) this.show()
  }

  conceal () {
    if (!this.manager.showLines) this.hide()
    this.opacity = defaultOpcity
    this.line.dash = false
  }

  drawLine (options) {
    var opts = { ...lineOptions, ...options }
    this.removeLine()
    this.line = new LeaderLine(this.start, this.end, opts)
  }
}

class MappedManager {
  constructor (dashBoard) {
    this.dashBoard = dashBoard
    this._showLines = true
    this._canMap = user.can_map
    this._lineProps = {
      path: 'straight',
      size: 6
    }
    this.mappeds = []
    Vue.set(this, 'mapped', this.mappeds)
    this.selected = {
      mapped: null,
      element: null,
      item: null,
      first: null,
      second: null
    }
  }

  get showLines () {
    return this._showLines
  }

  set showLines (value) {
    this._showLines = value
    if (value) {
      this.showAll()
    } else {
      this.hideAll()
    }
  }

  getMapped (first, second = null) {
    if (second) {
      for (const mapped of this.mappeds) {
        if (mapped.hasAccount(first.id) && mapped.hasAccount(second.id)) return mapped
      }
    } else {
      var mappeds = []
      for (const mapped of this.mappeds) {
        if (mapped.hasAccount(first.id)) mappeds.push(mapped)
      }
      if (mappeds.length === 0) return null
      return mappeds
    }
    return null
  }

  mappingExists (first, second) {
    for (let i = 0; i < this.mappeds.length; i++) {
      var map = this.mappeds[i]
      if (first === map.first) {
        if (second.app_id === map.second.app_id) return true
      }
    }
    return false
  }

  addMapped (first, second) {
    let mapped = this.getMapped(first, second)
    if (!mapped) {
      var mapOrder = this.checkMapping(first, second)
      if (mapOrder) {
        if (this.mappingExists(mapOrder.first, mapOrder.second)) {
          this.dashBoard.$dialog.error({
            text: 'Utente già mappato per questa applicazione.',
            title: 'AuthBridge'
          })
        } else {
          mapped = new Mapped(mapOrder.first, mapOrder.second, this)
          this.mappeds.push(mapped)
        }
      } else {
        this.dashBoard.$dialog.error({
          text: 'Mappatura non valida.',
          title: 'AuthBridge'
        })
      }
    } else {
      this.dashBoard.$dialog.error({
        text: 'Mappatura esistente.',
        title: 'AuthBridge'
      })
    }
    return mapped
  }

  removeMapped (mapped) {
    if (mapped) {
      if (!(mapped instanceof Array)) mapped = [mapped]
      mapped.forEach(m => m.cleanUp())
      this.mappeds = this.mappeds.filter(item => !mapped.includes(item))
    }
  }

  drawLines () {
    this.mappeds.forEach(m => m.drawLine())
  }

  showAll () {
    this.mappeds.forEach(m => m.show())
  }

  hideAll () {
    this.mappeds.forEach(m => m.hide())
  }

  concealAll () {
    this.mappeds.forEach(m => m.conceal())
  }

  cleanUp () {
    this.mappeds.forEach(m => m.cleanUp())
  }

  get path () {
    return this._lineProps.path
  }

  set path (path) {
    this._lineProps.path = path
    this.mappeds.forEach(m => { m.line.path = this._lineProps.path })
  }

  get size () {
    return this._lineProps.size
  }

  set size (value) {
    this._lineProps.size = value
    this.mappeds.forEach(m => { m.line.size = this._lineProps.size })
  }

  setLineProp (name, value) {
    this._lineProps[name] = value
    this.mappeds.forEach(m => { m.line[name] = value })
  }

  getLineProp (name) {
    return this._lineProps[name] || lineOptions[name]
  }

  addAll (items) {
    items.forEach(item => {
      var first = this.dashBoard.accounts.find(a => a.id === item[0].id)
      var second = this.dashBoard.accounts.find(a => a.id === item[1].id)
      if (first && second) {
        this.addMapped(first, second)
      }
    })
  }

  async fetchMapped () {
    this.mappeds.forEach(m => m.cleanUp())
    this.mappeds = []
    await axios.get('/accounts/list_mapped/')
      .then(r => {
        this.addAll(r.data)
        if (this.showLines) this.showAll()
      })
  }

  getElement (item) {
    return document.getElementById(`${item.app_id}-${item.id}`)
  }

  resetAllLines () {
    this.mappeds.forEach(m => {
      m.line.dash = false
    })
  }

  over (item) {
    (this.getMapped(item) || []).forEach(m => {
      m.line.dash = { animation: true }
      m.opacity = 1
      if (!this.showLines) m.show()
    })
  }

  leave (item) {
    (this.getMapped(item) || []).forEach(m => {
      if (!this.showLines) m.hide()
      m.opacity = defaultOpcity
      m.line.dash = false
    })
  }

  checkMapping (first, second) {
    const map = this._canMap.find(m => m.app_order.includes(first.app_id) && m.app_order.includes(second.app_id))
    if (map) {
      if (first.app_id === map.app_order[0]) {
        map.first = first
        map.second = second
      } else {
        map.first = second
        map.second = first
      }
      return map
    }
    return null
  }

  getRelatedApps (item) {
    return this._canMap.filter(m => m.app_order.includes(item.app_id))
      .map(m => m.app_order)
      .flat()
      .filter(m => m !== item.app_id)
  }

  getUnRelatedApps (item) {
    var related = [...this.getRelatedApps(item), item.app_id]
    return this._canMap.map(m => m.app_order)
      .flat()
      .filter(m => !related.includes(m))
  }

  markRelatedApps (item) {
    document.getElementsByClassName('app-list-item').forEach(i => {
      i.classList.remove('can-map-selectable')
    })
    this.getRelatedApps(item).forEach(appId => {
      var el = document.getElementById(`sso-app-${appId}`)
      if (el) el.classList.add('can-map-selectable')
    })
  }

  hideUnRelatedApps (item) {
    document.getElementsByClassName('app-list-item').forEach(i => {
      i.classList.remove('can-map-not-selectable')
    })
    this.getUnRelatedApps(item).forEach(appId => {
      var el = document.getElementById(`sso-app-${appId}`)
      if (el) el.classList.add('can-map-not-selectable')
    })
  }

  unmarkApps () {
    document.getElementsByClassName('app-list-item').forEach(i => {
      i.classList.remove('can-map-not-selectable', 'can-map-selectable')
    })
  }

  select (item, shift) {
    let newmap = null
    this.dashBoard.accounts.forEach(a => {
      a.startLink = false
      a.endLink = false
    })
    this.resetAllLines()
    var mapped = this.getMapped(item)
    this.selected.mapped = mapped
    this.selected.element = this.getElement(item)
    this.selected.item = item
    if (this.selected.mapped) {
      this.selected.mapped.forEach(m => {
        m.line.dash = { animation: true }
      })
      // this.selected.mapped.first.startLink = true
      // this.selected.mapped.second.endLink = true
    }
    if (this.selected.first === item) {
      if (this.selected.second) {
        this.selected.first = item
        this.selected.first.startLink = true
        this.selected.second.endLink = false
        this.selected.second = null
      } else {
        this.selected.first.startLink = false
        this.selected.first = null
      }
    } else if (this.selected.second === item) {
      this.selected.second.endLink = false
      this.selected.second = null
    } else if (!this.selected.first) {
      this.selected.first = item
      this.selected.first.startLink = true
    } else {
      if (this.selected.first.app_id === item.app_id) {
        this.dashBoard.$dialog.message.warning(
          'Impossibile collegare due account della stessa applicazione',
          { position: 'top-center' }
        )
      } else {
        this.selected.second = item
        this.selected.second.endLink = true
      }
    }
    if (this.selected.first) {
      this.markRelatedApps(this.selected.first)
      this.hideUnRelatedApps(this.selected.first)
    } else {
      this.unmarkApps()
    }
    if (this.selected.first && this.selected.second) {
      newmap = this.addMapped(this.selected.first, this.selected.second)
      if (newmap) newmap.show()
      this.unselect()
      this.unmarkApps()
    }
  }

  unselect () {
    this.dashBoard.accounts.forEach(a => {
      a.startLink = false
      a.endLink = false
    })
    this.selected.item.endLink = false
    this.selected.item.startLink = false
    this.selected = {
      mapped: null,
      element: null,
      item: null,
      first: null,
      second: null
    }
  }

  async showDialog () {
    if (this.visible) return true
    this.visible = true
    await this.fetchMapped()
    await this.dashBoard.$dialog.showAndWait(MappedManagerDialog, {
      dark: true,
      timeout: -1,
      container: '#core-view',
      attach: '#core-view',
      hideOverlay: true,
      persistent: true,
      mapper: this,
      noactions: {
        elimina: {
          small: true,
          color: 'warning',
          text: 'elimina',
          closable: false,
          handler: (params, action) => {
            this.removeMapped(this.mapman.selected.mapped)
          }
        },
        true: {
          small: true,
          color: 'primary',
          text: 'Salva'
        },
        false: {
          small: true,
          color: 'primary',
          text: 'Annulla'
        }
      }
    })
    this.visible = false
    this.dashBoard.isLinking = false
    this.cleanUp()
    return false
  }

  get simpliefied () {
    return this.mappeds.map(m => ([
      {
        app_id: m.first.app_id,
        id: m.first.id,
        username: m.first.username
      },
      {
        app_id: m.second.app_id,
        id: m.second.id,
        username: m.second.username
      }
    ]))
  }

  save () {
    axios.put('/accounts/list_mapped/', { mappeds: this.simpliefied })
      .then(r => {
        this.addAll(r.data)
        if (this.showLines) this.showAll()
      })
  }
}
export default MappedManager
