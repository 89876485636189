<template>
  <v-dialog
    v-model="showMe"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title
        class="text-h5 secondary white--text"
      >
        <v-icon
          class="white--text mr-2"
          large
        >
          mdi-account-plus
        </v-icon>
        Aggiungi account
      </v-card-title>
      <v-card-text>
        <p class="subtitle1 mt-2">
          Aggiungi o aggiorna un nuovo account tramite utente e password.
        </p>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
          >
            <l-app-selector
              v-model="selectedApp"
              mandatory
            />
            <p
              class="text-right"
              v-if="selectedApp.app && selectedApp.app.hasRecovery"
            >
              <router-link
                to="#"
                @click.native="doRecovery(selectedApp.app)"
              >
                Recupera Credenziali account {{ selectedApp.app.name }}
              </router-link>
            </p>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          outlined
          @click="showMe = false"
        >
          Annulla
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          @click="sendCode"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations } from 'vuex'
import messages from '@/utils/messages'
import AppSelector from './AppSelector.vue'
import RecoveryAccount from '../Account/RecoveryAccount.vue'
export default {
  components: {
    'l-app-selector': AppSelector
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: null,
      visibility: false,
      valid: false,
      username: null,
      password: null,
      localAppsList: null,
      selectedApp: {
        regType: 'dialog',
        app: {
          id: null,
          name: null,
          description: null,
          logo: null
        },
        login: {
          app_login: null,
          app_password: null
        }
      }
    }
  },
  computed: {
    showMe: {
      get () { return this.value || false },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    ...mapMutations('account', ['setAccounts']),
    sendCode () {
      if (this.$refs.form.validate()) {
        this.$http.post('/accounts/apps/account/add_account/', {
          app_id: this.selectedApp.app.id,
          username: this.selectedApp.login.username,
          password: this.selectedApp.login.password
        })
          .then(r => {
            this.setAccounts(r.data)
            messages.info('Utente aggiunto con successo')
            this.showMe = false
          })
          .catch(messages.error)
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    doRecovery (app) {
      const localApp = app || this.selectedApp.app
      this.$dialog.show(
        RecoveryAccount,
        {
          appId: localApp.id,
          appName: localApp.name
        }
      )
    }
  }
}
</script>
