<template>
  <masonry
    class="account-list"
    :cols="{default: 3, 1000: 2, 700: 1, 400: 1}"
    :gutter="{default: '30px', 700: '15px'}"
  >
    <material-account-card
      :is="$store.state.dashboard.layout === 'old' ? 'material-account-card' : 'sso-account-card'"
      v-for="account in items"
      :key="`${account.username}@${account.name}`"
      class="account-list-item"
      color="white"
      width="100%"
      :logo="account.logo"
      :title="account.username"
      :description="account.name"
      :account="account"
      :multi="items.length > 1"
      :is-deleting="isDeleting"
      :is-defaulting="isDefaulting"
      v-on="$listeners"
    />
  </masonry>
</template>
<script>
export default {
  props: {
    isDeleting: {
      type: Boolean,
      default: false
    },
    isDefaulting: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
<style lang="scss">
.account-list {
  max-height: calc( 100vh - 200px );
  overflow-y: auto;
  .account-list-item {
    margin-bottom: 30px;
  }
}
</style>
