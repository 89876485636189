<template>
  <masonry
    class="app-list"
    :cols="{ default: 3, 1000: 2, 700: 1, 400: 1 }"
    :gutter="{ default: '30px', 700: '15px' }"
  >
    <component
      :is="$store.state.dashboard.layout === 'old' ? 'material-app-card' : 'sso-app-card'"
      v-for="app in items"
      :key="app.name"
      :id="app.appId"
      :app="app"
      :class="getAppClass(app)"
      color="white"
      :logo="app.logo"
      :title="app.name"
      :description="app.description"
      :accounts="app.accounts"
      :is-linking="isLinking"
      :is-deleting="isDeleting"
      :is-defaulting="isDefaulting"
      v-on="$listeners"
    />
  </masonry>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    isLinking: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    isDefaulting: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      canMapApps: []
    }
  },
  computed: {
    ...mapState('account', ['user']),
    justify () {
      return 'center'
    }
  },
  mounted () {
    global.applist = this
    if (this.user.can_map) {
      this.canMapApps = [...new Set(this.user.can_map.map(m => ([m.requester.app_id, m.mapped.app_id])).flat())]
    } else {
      this.canMapApps = []
    }
  },
  methods: {
    getAppClass (app) {
      var cls = ['app-list-item']
      if (!this.canMapApps.includes(app.appId)) cls.push('can-map-diabled')
      return cls
    }
  }

}
</script>
<style lang="scss">
.app-list {
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  .app-list-item {
    margin-bottom: 30px;
  }

  .sso-app-card {
    &.app-list-item {
      &.is-linking {
        &.can-map-diabled {
          opacity: 0;
          pointer-events: none;
        }

        &.can-map-selectable {
          border-color: red;
        }

        &.can-map-not-selectable {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
