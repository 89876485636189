<template>
  <sso-message-card
    title="Mapped Users"
    :actions="actions"
    type="info"
    :icon="null"
    v-ep-drag="{ handler: '.sso-message-card-head' }"
  >
    <v-card-text>
      <v-list
        shaped
      >
        <v-list-item-group
          v-model="selected"
          @change="change"
        >
          <v-list-item
            v-for="(mapped, index) in mapper.mappeds"
            :key="index"
          >
            <v-img
              max-height="32"
              max-width="32"
              contain
              :src="mapped.first.logo"
            />
            <span class="mx-2">
              {{ mapped.first.username }}
            </span>
            <v-icon>mdi-swap-horizontal-bold</v-icon>
            <span class="mx-2">
              {{ mapped.second.username }}
            </span>
            <v-img
              max-height="32"
              max-width="32"
              contain
              :src="mapped.second.logo"
            />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-text>
      <v-select
        :items="paths"
        label="tipo linea"
        v-model="path"
      />
      <v-slider
        v-model="size"
        :thumb-size="24"
        thumb-label="always"
        :min="5"
        :max="15"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="warning"
        text
        outlined
        @click="remove"
      >
        Elimina
      </v-btn>
      <v-btn
        color="primary"
        text
        outlined
        @click="save"
      >
        Salva
      </v-btn>
      <v-btn
        color="primary"
        text
        outlined
        @click="close"
      >
        Annulla
      </v-btn>
    </v-card-actions>
  </sso-message-card>
</template>
<script>
export default {
  props: {
    mapper: {
      type: Object,
      default: undefined
    },
    actions: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  data () {
    return {
      selected: undefined,
      paths: [
        { text: 'Lineare', value: 'straight' },
        { text: 'Arco', value: 'arc' },
        { text: 'Fluido', value: 'fluid' },
        { text: 'Magnetico', value: 'magnet' },
        { text: 'Griglia', value: 'grid' }
      ]
    }
  },
  mounted () {
    global.mudialog = this
  },
  computed: {
    path: {
      get () {
        return this.mapper.path
      },
      set (value) {
        this.mapper.path = value
      }
    },
    size: {
      get () {
        return this.mapper.size
      },
      set (value) {
        this.mapper.size = value
      }
    }
  },
  methods: {
    change (item) {
      this.mapper.concealAll()
      if (item !== undefined) this.mapper.mappeds[item].reveal()
    },
    close () {
      this.$emit('submit', false)
    },
    save () {
      this.mapper.save()
      this.close()
    },
    remove () {
      if (this.selected === undefined) {
        this.$dialog.error({ text: 'Nessun elemento selezionato' })
        return
      }
      this.mapper.removeMapped(this.mapper.mappeds[this.selected])
    }
  }
}
</script>
